import {
  APIGetRequest,
  OrderFormat,
  ProductDetails,
  SystemVisibility,
  DayOfWeek,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type ProductsAPI = {
  get: {
    details: APIGetRequest<
      ProductDetails,
      {
        productId: string
        outletId: string
        systemVisibility: SystemVisibility
        orderFormat: OrderFormat
        purchaseOrderFormat: OrderFormat
        orderTime: string
        dayOfWeek: DayOfWeek
      }
    >
  }
}

const products: ProductsAPI = {
  get: {
    details: new Request(endpoints.core.outlets.byId.products.byId.details).get,
  },
}

export default products
