import clsx from 'clsx'
import {
  UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form'

import BodyText from './BodyText'
import Input, { InputProps } from './Input'
import FormInputErrorLabel from './FormInputErrorLabel'
import styles from './FormInput.module.scss'

type ReactHookFormRules = UseControllerProps['rules']

interface FormInputProps extends InputProps {
  /* Name of the field in form state */
  fieldName: string
  /* Text label of the input */
  label?: string
  /* Validation rules of the input */
  rules?: ReactHookFormRules
  /* Customized Error Message */
  renderCustomErrorComponent?: (errorMessage: string) => React.ReactNode
  /* Class name of the container */
  containerClassName?: string
  /* Class name for the label */
  labelClassName?: string
  /* Whether input is required */
  required?: boolean
  /* Whether to trim whitespace */
  noWhiteSpaces?: boolean
  /* Whether to show error if touched */
  showErrorOnTouched?: boolean
  /* Allow only numbers to be entered */
  onlyAllowNumbers?: boolean
}

function FormInput({
  fieldName,
  label,
  rules,
  renderCustomErrorComponent,
  readOnly,
  containerClassName,
  labelClassName,
  required,
  noWhiteSpaces = false,
  showErrorOnTouched = true,
  onlyAllowNumbers = false,
  ...inputProps
}: FormInputProps) {
  const { setValue } = useFormContext()

  const {
    field: { onBlur, onChange, value, ref },
    fieldState: { error, isTouched },
  } = useController({
    name: fieldName,
    rules,
  })

  const hasError = !!error && (!showErrorOnTouched || isTouched)

  function generateErrorText() {
    if (!hasError) return ''

    return `${error.message}`
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let text = event.target.value

    // Check whether text only contains whitespace
    if (text && !text.replace(/\s/g, '').length) {
      return
    }

    if (text.length) {
      if (noWhiteSpaces) {
        text = text.replace(/\s/g, '')
      }

      if (onlyAllowNumbers) {
        text = text.replace(/\D/g, '')
      }
    }

    onChange(text)
    setValue(fieldName, text)
  }

  return (
    <div className={clsx(styles.container, containerClassName)}>
      {label && (
        <BodyText
          as="label"
          color="body-1"
          className={clsx(styles.label, labelClassName)}
        >
          {label}
          {required && <span>{' *'}</span>}
        </BodyText>
      )}
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        ref={ref}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        invalid={hasError}
        readOnly={readOnly}
        className={clsx(!hasError && styles.valid, readOnly && styles.readOnly)}
      />
      {hasError ? (
        (renderCustomErrorComponent &&
          renderCustomErrorComponent(generateErrorText())) || (
          <FormInputErrorLabel htmlFor={fieldName} error={error?.message} />
        )
      ) : (
        <div className={styles.spacer} />
      )}
    </div>
  )
}

export default FormInput
