import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import authReducer from '../features/auth/store/authSlice'
import clientContextReducer from '../features/clientContext/store/clientContextSlice'
import companyReducer from '../features/company/store/companySlice'
import notificationReducer from '../features/notification/store/notificationSlice'
import outletsReducer from '../features/outlets/store/outletsSlice'
import locationReducer from '../features/location/store/locationSlice'
import outletReducer from '../features/outlet/store/outletSlice'
import productReducer from '../features/product/store/productSlice'
import checkoutReducer from '../features/checkout/store/checkoutSlice'
import appReducers from '../features/app/store/appSlice'
import ordersSlice from '../features/orders/store/ordersSlice'
import accountSlice from '../features/account/store/accountSlice'
import preOrderReducer from '../features/preOrder/store/preOrderSlice'

const combinedReducer = combineReducers({
  notification: notificationReducer,
  clientContext: clientContextReducer,
  auth: authReducer,
  company: companyReducer,
  outlets: outletsReducer,
  location: locationReducer,
  outlet: outletReducer,
  product: productReducer,
  checkout: checkoutReducer,
  app: appReducers,
  orders: ordersSlice,
  account: accountSlice,
  preOrder: preOrderReducer,
})

export type RootState = ReturnType<typeof combinedReducer>

// https://github.com/kirill-konshin/next-redux-wrapper#state-reconciliation-during-hydration
function rootReducer(
  state: RootState | undefined,
  action: AnyAction,
): RootState {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }

    return nextState
  }

  return combinedReducer(state, action)
}

export default rootReducer
