import moment from 'moment'

import { DaysOfWeekMap } from '../../app/constants'

export default function generateOrderTimeFilters(orderTime?: string) {
  const configuredTime = moment(orderTime)
  const weekDayIndex = configuredTime.isoWeekday()

  return {
    dayOfWeek: DaysOfWeekMap[weekDayIndex],
    orderTime: configuredTime.utc().format('YYYY-MM-DDTHH:mm:ss'),
  }
}
