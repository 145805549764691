import { v4 } from 'uuid'

import localStorageUtils from '../../app/utils/localStorageUtils'

type StoredPaymentCorrelationIdSubscription = (id: string) => void

type Unsubcribe = () => void

class StoredPaymentCorrelationId {
  private id: string | null = null

  private subscriptions: StoredPaymentCorrelationIdSubscription[] = []

  public subscribe(
    subscription: StoredPaymentCorrelationIdSubscription,
  ): Unsubcribe {
    const index = this.subscriptions.push(subscription) - 1

    return () => {
      this.subscriptions.splice(index, 1)
    }
  }

  public get() {
    const stored = localStorageUtils.getItem<string | null>(
      'paymentCorrelationId',
    )

    if (stored && typeof stored === 'string') {
      this.id = stored
    } else {
      this.id = v4()
      localStorageUtils.setItem('paymentCorrelationId', this.id)
    }

    return this.id
  }

  public refresh() {
    const id = v4()
    localStorageUtils.setItem('paymentCorrelationId', id)
    this.subscriptions.forEach(subscription => subscription(id))
    this.id = id
  }

  public clear() {
    const id = v4()
    localStorageUtils.removeItem('paymentCorrelationId')
    this.subscriptions.forEach(subscription => subscription(id))
    this.id = id
  }
}

const storedPaymentCorrelationId = new StoredPaymentCorrelationId()

export default storedPaymentCorrelationId
