import {
  ErrorCode,
  MenuTreeHierarchyItem,
  MenuTreeHierarchyProduct,
  MenuTreeHierarchyProductStockCount,
  OrderFormat,
  ProductDetails,
  SystemVisibility,
} from '@ancon/wildcat-types'
import isDetailedProductOutOfStock from '@ancon/wildcat-utils/inventory/isDetailedProductOutOfStock'
import createCodedError from '@ancon/wildcat-utils/error/createCodedError'
import { orderBy } from 'lodash'
import getDetailedProductVariantStock from '@ancon/wildcat-utils/inventory/getDetailedProductVariantStock'

import api from '../../../api'
import generateOrderTimeFilters from '../utils/generateOrderTimeFilters'
import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import {
  outletFiltersSelector,
  outletListItemSelector,
  outletSelectedOutletIdSelector,
} from '../../outlet/store/outletSelector'
import { appShowError } from '../../app/store/appSlice'
import getTranslatedError from '../../app/utils/getTranslatedError'
import { ErrorModalType } from '../../app/types'
import {
  checkoutCurrentCheckoutOutletIdSelector,
  checkoutCurrentCheckoutOutletListItemSelector,
  checkoutCurrentCheckoutSelectedItemIdSelector,
  checkoutFiltersSelector,
} from '../../checkout/store/checkoutSelectors'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { ProductsSearchAPILimit } from '../constants'

import { productByIdSelector } from './productSelectors'

export const fetchProductDetails = createAppAsyncThunk<
  {
    productDetails: ProductDetails
    productMenuStocks?: MenuTreeHierarchyProductStockCount[]
  },
  {
    productId: string
    outletId?: string | null
    orderFormat?: OrderFormat | null
    orderDateTime?: string
  }
>(
  'product/fetchProductDetails',
  async (
    { productId, outletId, orderFormat, orderDateTime },
    { getState, dispatch },
  ) => {
    const checkoutOutletId = checkoutCurrentCheckoutOutletIdSelector(getState())
    const selectedCheckoutItemId =
      checkoutCurrentCheckoutSelectedItemIdSelector(getState())
    const selectedOutletId = outletSelectedOutletIdSelector(getState())
    const outletFilters = outletFiltersSelector(getState())
    const currentOrderFormat = orderFormat ?? outletFilters.orderFormat!
    const currentOrderTime =
      (orderDateTime ?? outletFilters.serviceTime?.time) || undefined
    const currentOutletId =
      outletId ?? (selectedCheckoutItemId ? checkoutOutletId : selectedOutletId)

    const response = await api.core.products.get.details({
      productId,
      outletId: currentOutletId!,
      systemVisibility: SystemVisibility.OrderWeb,
      orderFormat: currentOrderFormat,
      purchaseOrderFormat: currentOrderFormat,
      ...generateOrderTimeFilters(currentOrderTime),
    })

    const productDetails = response?.data!

    const {
      variants,
      hasIngredient,
      ingredients,
      addOnGroups,
      hasAddOnGroups,
      hasVariants,
      upsellGroups,
    } = productDetails

    const organizedProductDetails = {
      ...productDetails,
      ...(hasIngredient && {
        ingredients: orderBy(ingredients, 'position', 'asc'),
      }),
      ...(hasVariants && {
        variants: orderBy(variants, 'position', 'asc'),
      }),
      ...(upsellGroups.length && {
        upsellGroups: orderBy(upsellGroups, 'position', 'asc').map(
          upsellGroup => ({
            ...upsellGroup,
            products: orderBy(upsellGroup.products, 'position', 'asc'),
          }),
        ),
      }),
      ...(hasAddOnGroups && {
        addOnGroups: orderBy(addOnGroups, 'position', 'asc').map(
          addonGroup => ({
            ...addonGroup,
            ingredients: orderBy(addonGroup.ingredients, 'position', 'asc'),
          }),
        ),
      }),
    }

    const isProductOutOfStock = isDetailedProductOutOfStock(
      currentOutletId!,
      variants,
    )

    if (isProductOutOfStock) {
      const locale = appLanguageSelector(getState())
      const error = await getTranslatedError(
        createCodedError('Out of Stock', ErrorCode.ProductOutOfStock),
        undefined,
        locale,
      )

      dispatch(
        appShowError({
          ...error,
          errorModalType: ErrorModalType.ProductOutOfStock,
        }),
      )

      const productEntity = productByIdSelector(getState(), productId)
      if (productEntity) {
        const productMenuStocks = variants.map(variant => {
          const variantStock = getDetailedProductVariantStock(
            variant,
            currentOutletId!,
          )!
          return {
            stockProductId: variantStock.stockProductId,
            count: variantStock.stockCount,
            reorderCount: variantStock.reorderCount,
            variantId: variant.id,
          }
        })

        return {
          productDetails: organizedProductDetails,
          productMenuStocks,
        }
      }
    }

    return { productDetails: organizedProductDetails }
  },
)

export const fetchOutletMenuGroups = createAppAsyncThunk<
  MenuTreeHierarchyItem[],
  void
>('product/fetchOutletMenuGroups', async (_, { getState }) => {
  const outlet = outletListItemSelector(getState())
  const outletFilters = outletFiltersSelector(getState())

  if (outlet) {
    const response = await api.core.menuTrees.get.hierarchy({
      outletId: outlet.id,
      menuTreeId: outlet.mandatoryMenuTreeId,
      orderFormats:
        outlet.showAvailableProductsFromOtherOrderFormats ||
        !outletFilters?.orderFormat
          ? OrderFormat.None
          : outletFilters.orderFormat,
      purchaseOrderFormat: outletFilters?.orderFormat || undefined,
      systemVisibility: SystemVisibility.OrderWeb,
      ...generateOrderTimeFilters(outletFilters.serviceTime?.time || undefined),
    })

    const menuGroups = (response?.data?.menuTreeItems || []).sort(
      (a, b) => a.sortOrder - b.sortOrder,
    )

    return menuGroups
  }

  return []
})

export const searchMenuTreeProducts = createAppAsyncThunk<
  MenuTreeHierarchyProduct[],
  { search: string; limit?: number; offset?: number }
>(
  'product/searchMenuTreeProducts',
  async (
    { search, limit = ProductsSearchAPILimit, offset = 0 },
    { getState },
  ) => {
    const outlet = checkoutCurrentCheckoutOutletListItemSelector(getState())
    const outletFilters = checkoutFiltersSelector(getState())

    if (outlet) {
      const response = await api.core.menuTrees.get.products({
        search,
        limit,
        offset,
        outletId: outlet.id,
        menuTreeId: outlet.mandatoryMenuTreeId,
        orderFormats:
          outlet.showAvailableProductsFromOtherOrderFormats ||
          !outletFilters?.orderFormat
            ? OrderFormat.None
            : outletFilters.orderFormat,
        purchaseOrderFormat: outletFilters?.orderFormat || undefined,
        systemVisibility: SystemVisibility.OrderWeb,
        ...generateOrderTimeFilters(
          outletFilters.serviceTime?.time || undefined,
        ),
      })

      return response.data.items
    }

    return []
  },
)
