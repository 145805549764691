import outlets from './outlets'
import outlet from './outlet'
import products from './products'
import clientContext from './clientContext'
import tables from './tables'
import menuTrees from './menuTrees'
import checkouts from './checkouts'
import payments from './payments'
import companies from './companies'
import identityUsers from './identityUsers'
import tickets from './tickets'
import orderReceipt from './orderReceipt'
import orders from './orders'
import order from './order'
import preOrders from './preOrders'
import integrations from './integrations'
import stations from './stations'

const core = {
  products,
  outlets,
  outlet,
  clientContext,
  identityUsers,
  tables,
  menuTrees,
  checkouts,
  payments,
  companies,
  tickets,
  orderReceipt,
  orders,
  order,
  preOrders,
  integrations,
  stations,
}

export default core
