import clsx from 'clsx'
import { PropsWithChildren } from 'react'

import HeadingText from '../../app/components/HeadingText'
import BodyText from '../../app/components/BodyText'

import styles from './ProductConfigurationSection.module.scss'

type ProductConfigurationSectionProps = PropsWithChildren<{
  title: string | React.ReactNode
  subtitle?: string
  additionalInfo?: string
  hasError?: boolean
}>

export default function ProductConfigurationSection({
  title,
  subtitle,
  additionalInfo,
  hasError,
  children,
}: ProductConfigurationSectionProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          {typeof title === 'string' ? (
            <HeadingText as="h3">{title}</HeadingText>
          ) : (
            title
          )}
        </div>
        {subtitle && (
          <BodyText
            className={clsx(styles.subtitle, {
              [styles.error]: hasError,
            })}
          >
            {subtitle}
          </BodyText>
        )}
        {additionalInfo && <BodyText color="body-1">{additionalInfo}</BodyText>}
      </div>

      {children}
    </div>
  )
}
