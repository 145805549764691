import { useEffect } from 'react'
import { ProductDetailsAddonIngredient } from '@ancon/wildcat-types'
import { getFormattedCurrency } from '@ancon/wildcat-utils/currency'
import { useController, useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import {
  ConfigureAddonGroupFormState,
  ConfigureAddonIngredientFormState,
} from '@ancon/wildcat-utils/product/types'

import QuantityModifier from '../../../app/components/QuantityModifier'
import BodyText from '../../../app/components/BodyText'

import styles from './QuantityModifierAddonGroupForm.module.scss'

type QuantityModifierAddonGroupProps = {
  ingredients: ProductDetailsAddonIngredient[]
  addOnIndex: number
}

type QuantityModifierAddonIngredientProps = {
  ingredient: ProductDetailsAddonIngredient
  addOnIndex: number
  ingredientIndex: number
}

function QuantityModifierAddonIngredient({
  ingredient,
  addOnIndex,
  ingredientIndex,
}: QuantityModifierAddonIngredientProps) {
  const selectedIngredientCountFieldName = `selectedAddons.${addOnIndex}.ingredients.${ingredientIndex}.selectedCount`
  const freeIngredientAmountFieldName = `selectedAddons.${addOnIndex}.ingredients.${ingredientIndex}.freeAmount`
  const addOnIngredientsFieldName = `selectedAddons.${addOnIndex}.ingredients`
  const addOnSelectedCountFieldName = `selectedAddons.${addOnIndex}.selectedCount`
  const addOnSettingsFieldName = `selectedAddons.${addOnIndex}.addOnSettings`

  const { t } = useTranslation('common')
  const { trigger, setValue, watch, getValues } = useFormContext()
  const [selectedAddOnCount, addOnSettings] = watch([
    addOnSelectedCountFieldName,
    addOnSettingsFieldName,
  ])

  const hasFreeAddons = !!addOnSettings.freeAmount
  const isWithinFreeLimit =
    hasFreeAddons && addOnSettings.freeAmount! > selectedAddOnCount

  const {
    field: { value, onChange },
  } = useController({
    name: selectedIngredientCountFieldName,
  })
  const { ingredientName, price, currency, id } = ingredient

  function handleQuantityChange(quantity: number, isIncrement: boolean) {
    onChange(quantity)

    const currentAddOnSelectedCount = getValues(addOnSelectedCountFieldName)
    setValue(
      addOnSelectedCountFieldName,
      Math.max(
        isIncrement
          ? currentAddOnSelectedCount + 1
          : currentAddOnSelectedCount - 1,
        0,
      ),
    )

    // Modify free addon state - Start
    const [currentFreeAmount, currentSelectedCount] = getValues([
      freeIngredientAmountFieldName,
      selectedIngredientCountFieldName,
    ])

    // On increase add as free addOn
    if (isIncrement && isWithinFreeLimit) {
      setValue(freeIngredientAmountFieldName, currentFreeAmount + 1)
      trigger(`selectedAddons.${addOnIndex}`)
      return
    }

    // On decrease free addon assign free item to next candidate addOn item
    const hasFreeAdditions = currentFreeAmount > 0
    if (
      !isIncrement &&
      hasFreeAdditions &&
      currentSelectedCount < currentFreeAmount
    ) {
      setValue(freeIngredientAmountFieldName, currentFreeAmount - 1)
      const ingredientSelectionState: ConfigureAddonIngredientFormState[] =
        getValues(addOnIngredientsFieldName)
      const nextFreeCandidateIngredientIndex =
        ingredientSelectionState.findIndex(
          ing => ing.id !== id && ing.freeAmount < ing.selectedCount,
        )
      if (nextFreeCandidateIngredientIndex !== -1) {
        const candidateIngredientFreeAmountFieldName = `selectedAddons.${addOnIndex}.ingredients.${nextFreeCandidateIngredientIndex}.freeAmount`
        const candidateCurrentFreeAmount = getValues(
          candidateIngredientFreeAmountFieldName,
        )
        setValue(
          candidateIngredientFreeAmountFieldName,
          candidateCurrentFreeAmount + 1,
        )
      }
    }
    // Modify free addon state - End

    // Trigger Validation Cycle
    trigger(`selectedAddons.${addOnIndex}`)
  }

  return (
    <div className={styles.container}>
      <BodyText>{ingredientName}</BodyText>
      <div className={styles.leftContainer}>
        <QuantityModifier
          className={styles.quantityModifier}
          initialValue={value}
          min={0}
          max={
            addOnSettings.maximumAmount &&
            selectedAddOnCount >= addOnSettings.maximumAmount
              ? value
              : addOnSettings.maximumAmount
          }
          onChange={handleQuantityChange}
        />
        <BodyText className={styles.priceText}>
          {isWithinFreeLimit
            ? t('components.productDetailsModal.free')
            : getFormattedCurrency(
                hasFreeAddons ? addOnSettings.afterFreePrice.amount : price,
                currency,
              )}
        </BodyText>
      </div>
    </div>
  )
}

export default function QuantityModifierAddonGroup({
  ingredients,
  addOnIndex,
}: QuantityModifierAddonGroupProps) {
  const { t } = useTranslation('common')
  const { trigger } = useFormContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const field = useController({
    name: `selectedAddons.${addOnIndex}`,
    rules: {
      validate: (value: ConfigureAddonGroupFormState) => {
        const { selectedCount: totalAddonCount, addOnSettings } = value
        const { maximumAmount, minimumAmount } = addOnSettings

        if (minimumAmount && totalAddonCount === 0) {
          return t('components.productDetailsModal.mandatoryChoice').toString()
        }
        if (minimumAmount && totalAddonCount < minimumAmount) {
          return t('components.productDetailsModal.atLeastAddons', {
            minimum: minimumAmount,
          }).toString()
        }
        if (maximumAmount && totalAddonCount > maximumAmount) {
          return t('components.productDetailsModal.maxAddons', {
            maximum: maximumAmount,
          }).toString()
        }
        return true
      },
    },
  })

  useEffect(() => {
    // Trigger Initial Validation Cycle
    trigger(`selectedAddons.${addOnIndex}`)
  }, [trigger, addOnIndex])

  return ingredients.map((ingredient, i) => (
    <QuantityModifierAddonIngredient
      key={ingredient.id}
      ingredient={ingredient}
      addOnIndex={addOnIndex}
      ingredientIndex={i}
    />
  ))
}
