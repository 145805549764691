import { CheckoutMetaDataItem, OrderFormat } from '@ancon/wildcat-types'
import getCheckoutPenguinLockersMetaData from '@ancon/wildcat-utils/checkout/getCheckoutPenguinLockersMetaData'

import { CheckoutFilters } from '../types'

export default function getCheckoutMetaData(
  checkoutFilters: CheckoutFilters,
  checkoutMetaData?: CheckoutMetaDataItem[],
): string | null {
  if (
    checkoutFilters.orderFormat === OrderFormat.BoxPickup &&
    checkoutFilters?.section?.id
  ) {
    const { section } = checkoutFilters
    const penguinLockersMetaData =
      getCheckoutPenguinLockersMetaData(checkoutMetaData)

    const metaData = {
      station: section,
      timeBegin: penguinLockersMetaData?.timeBegin,
      expireTime: penguinLockersMetaData?.expireTime,
      pickingTime: penguinLockersMetaData?.pickingTime,
      preferredServiceTime: penguinLockersMetaData?.preferredServiceTime,
    }

    return JSON.stringify(metaData)
  }

  return null
}
