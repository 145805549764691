import { useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import getAddonGroupInfoBySettings, {
  SettingTranslationKey,
  SettingTranslationMeta,
  SettingsTranslatedTexts,
} from '@ancon/wildcat-utils/product/getAddonGroupInfoBySettings'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import ProductConfigurationSection from '../../components/ProductConfigurationSection'
import { productsDetailsAddonGroupsSelector } from '../../store/productSelectors'
import { ConfigureProductFormState } from '../../types'

import styles from './ProductAddonGroupsForm.module.scss'
import CheckBoxTypeAddonGroup from './CheckBoxTypeAddonGroup'
import RadioButtonTypeAddonGroup from './RadioButtonTypeAddonGroup'
import QuantityModifierAddonGroup from './QuantityModifierAddonGroupForm'

export default function ProductAddonGroupsForm() {
  const addonGroups = useAppSelector(productsDetailsAddonGroupsSelector)!
  const { formState } = useFormContext<ConfigureProductFormState>()
  const { errors } = formState
  const { t } = useTranslation('common')

  function addonGroupSettingTranslation({
    key,
    meta,
  }: {
    key: SettingTranslationKey
    meta?: SettingTranslationMeta
  }) {
    return t(`components.productDetailsModal.${key}`, meta) as string
  }

  const addonGroupSettingsTexts = Object.values(SettingTranslationKey).reduce(
    (acc, k) => {
      acc[k] = addonGroupSettingTranslation

      return acc
    },
    {} as SettingsTranslatedTexts,
  )

  return (
    <div className={styles.container}>
      {addonGroups.map(
        ({ addOnGroupId, addOnGroupName, ingredients, addOnSettings }, i) => {
          const { allowMultiple, maximumAmount, minimumAmount } = addOnSettings

          const shouldRenderQuantityButton = allowMultiple
          const shouldRenderRadioButton =
            !allowMultiple &&
            maximumAmount &&
            maximumAmount < 2 &&
            minimumAmount &&
            minimumAmount < 2
          const shouldRenderCheckBox =
            !shouldRenderRadioButton && !shouldRenderQuantityButton

          const addonGroupError = errors?.selectedAddons?.[i]
          const hasErrors = !!addonGroupError
          const errorMessage =
            addonGroupError?.message ||
            addonGroupError?.selectedIngredientId?.message

          const { translation: addonSettingsInfo } =
            getAddonGroupInfoBySettings(addOnSettings, addonGroupSettingsTexts)

          return (
            <div className={styles.addonContainer} key={addOnGroupId}>
              <ProductConfigurationSection
                title={addOnGroupName}
                subtitle={errorMessage}
                additionalInfo={addonSettingsInfo}
                hasError={hasErrors}
              >
                {shouldRenderCheckBox && (
                  <CheckBoxTypeAddonGroup
                    ingredients={ingredients || []}
                    addOnIndex={i}
                  />
                )}
                {shouldRenderRadioButton && (
                  <RadioButtonTypeAddonGroup
                    ingredients={ingredients || []}
                    addonIndex={i}
                  />
                )}
                {shouldRenderQuantityButton && (
                  <QuantityModifierAddonGroup
                    ingredients={ingredients || []}
                    addOnIndex={i}
                  />
                )}
              </ProductConfigurationSection>
            </div>
          )
        },
      )}
    </div>
  )
}
