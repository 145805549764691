import {
  OutletListItem,
  SystemVisibility,
  OutletState,
  ErrorCode,
  OutletDetails,
  TableSuggestion,
  BoxPickUpStation,
  BoxPickUpStationTimeSlot,
} from '@ancon/wildcat-types'
import createCodedError from '@ancon/wildcat-utils/error/createCodedError'
import getTranslation from 'next-translate/getT'
import serializeError from '@ancon/wildcat-utils/error/serializeError'
import { v4 } from 'uuid'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import api from '../../../api'
import { appShowError } from '../../app/store/appSlice'
import getTranslatedError from '../../app/utils/getTranslatedError'
import { appLanguageSelector } from '../../app/store/appSelectors'
import getTenantIds from '../../app/utils/getTenantIds'
import {
  checkoutCurrentCheckoutOutletIdSelector,
  checkoutCurrentCheckoutPenguinLockersMetaDataSelector,
  checkoutFiltersSectionIdSelector,
  checkoutFiltersServiceTimeSelector,
} from '../../checkout/store/checkoutSelectors'
import { AOWAllOrderFormats } from '../../app/constants'

export const fetchOutletListItem = createAppAsyncThunk<
  OutletListItem | null,
  { urlSlug?: string | null; outletId?: string | null }
>('outlet/fetchOutletListItem', async ({ urlSlug, outletId }) => {
  const params = urlSlug
    ? { urlSlug: urlSlug ?? undefined }
    : { outletId: outletId ?? undefined }
  const tenantIds = getTenantIds()
  const correlationId = v4()

  try {
    const response = await api.core.outlets.get.nearby(
      {
        ...params,
        ...(tenantIds?.length && {
          filter: {
            tenantId: tenantIds.map(id => `in:${id}`),
          },
        }),
        orderFormats: AOWAllOrderFormats,
        systemVisibility: SystemVisibility.OrderWeb,
      },
      {
        'x-correlation-id': correlationId,
      },
    )

    return response?.data?.items[0] ?? null
  } catch (error: any) {
    console.log(`${correlationId} - Outlet Fetch Failed`, {
      error: error.response?.data || error.message || error,
    })

    return null
  }
})

export const validateTableOrderUrl = createAppAsyncThunk<
  {
    tableId: string | undefined
    tableNumber: number | undefined
  },
  {
    outletId: string
    tableId: string
  }
>(
  'outlets/validateTableOrderUrl',
  async ({ outletId, tableId }, { getState, dispatch }) => {
    const locale = appLanguageSelector(getState())
    const t = await getTranslation(locale, 'common')
    const tenantIds = getTenantIds()
    const { outletListItem } = getState().outlet
    const isOutletClosed = outletListItem?.state === OutletState.Closed
    const fallBackReturn = {
      tableId: undefined,
      tableNumber: undefined,
    }

    if (isOutletClosed) {
      const error = await getTranslatedError(
        createCodedError(
          'Outlet Closed',
          ErrorCode.QRTableOrderingOutletClosed,
        ),
        undefined,
        locale,
      )
      dispatch(appShowError(error))
      return fallBackReturn
    }

    try {
      if (outletListItem && tableId) {
        const response = await api.core.tables.get.details({
          tableId,
          outletId,
          ...(tenantIds?.length && {
            filter: {
              tenantId: tenantIds.map(id => `in:${id}`),
            },
          }),
        })
        if (response?.data.availableForTableOrder) {
          const tableDetails = response?.data
          return {
            tableId,
            tableNumber: tableDetails.number,
          }
        }
        const error = await getTranslatedError(
          createCodedError(
            'Table Not Available',
            ErrorCode.QRTableOrderingTableUnavailable,
          ),
          undefined,
          locale,
        )
        dispatch(appShowError(error))
        return fallBackReturn
      }
      const error = await getTranslatedError(
        createCodedError('Invalid QR Code', ErrorCode.QRTableOrderingInvalidQR),
        undefined,
        locale,
      )
      dispatch(appShowError(error))
      return fallBackReturn
    } catch (err) {
      dispatch(
        appShowError({
          title: t('errors.qRTableOrderingUnknown.title'),
          message: t('errors.qRTableOrderingUnknown.message'),
        }),
      )
      throw err
    }
  },
)

export const fetchOutletDetails = createAppAsyncThunk<
  OutletDetails | null,
  { outletId: string; skipStoreUpdate?: boolean }
>('outlet/fetchOutletDetails', async ({ outletId }) => {
  const response = await api.core.outlet.get.details({
    outletId,
  })

  return response?.data || null
})

export const validateTableNumber = createAppAsyncThunk<
  TableSuggestion,
  { tableNumber: number; outletId: string }
>(
  'outlet/validateTableNumber',
  async ({ tableNumber, outletId }, { rejectWithValue, getState }) => {
    const locale = appLanguageSelector(getState())
    const tenantIds = getTenantIds()

    try {
      const response = await api.core.tables.get.suggestions({
        outletId,
        filter: {
          number: `in:${tableNumber}`,
          ...(tenantIds?.length && {
            tenantId: tenantIds.map(id => `in:${id}`),
          }),
        },
      })

      if (response && response.data.items.length !== 0) {
        const { id, availableForTableOrder, number } = response.data.items[0]

        if (availableForTableOrder) {
          return {
            id,
            number,
            availableForTableOrder,
          }
        }

        return rejectWithValue(
          await getTranslatedError(
            createCodedError(
              'Table Not Available',
              ErrorCode.QRTableOrderingTableUnavailable,
            ),
            undefined,
            locale,
          ),
        )
      }

      return rejectWithValue(
        await getTranslatedError(
          createCodedError(
            'Table Not Found',
            ErrorCode.QRTableOrderingTableNotFound,
          ),
          undefined,
          locale,
        ),
      )
    } catch (err) {
      const t = await getTranslation(locale, 'common')

      return rejectWithValue(
        await getTranslatedError(
          err,
          {
            title: t('errors.qRTableOrderingUnknown.title'),
            message: t('errors.qRTableOrderingUnknown.message'),
          },
          locale,
        ),
      )
    }
  },
)

export const fetchOutletStations = createAppAsyncThunk<
  { items: BoxPickUpStation[] },
  { outletId: string }
>(
  'outlet/fetchOutletStations',
  async ({ outletId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await api.core.stations.get.stations({ outletId })
      return response?.data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')

      const error = await getTranslatedError(
        err,
        {
          title: t('errors.outletStationsFailure.title'),
          message: t('errors.outletStationsFailure.message'),
        },
        locale,
      )
      dispatch(appShowError(error))
      return rejectWithValue(error)
    }
  },
)

export const fetchOutletStationTimeSlots = createAppAsyncThunk<
  {
    items: BoxPickUpStationTimeSlot[]
  },
  number | undefined
>(
  'outlet/fetchOutletStationTimeSlots',
  async (selectedStationId, { rejectWithValue, getState, dispatch }) => {
    try {
      const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!
      const stationId =
        selectedStationId || checkoutFiltersSectionIdSelector(getState())!
      const serviceTime = checkoutFiltersServiceTimeSelector(getState())
      const preferredServiceTime =
        checkoutCurrentCheckoutPenguinLockersMetaDataSelector(
          getState(),
        )?.preferredServiceTime
      const timeSlotDate = preferredServiceTime || serviceTime?.time

      const response = await api.core.stations.get.stationTimeSlots({
        outletId,
        stationId,
        timeSlotDate,
      })
      return response?.data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')

      const error = await getTranslatedError(
        err,
        {
          title: t('errors.outletStationTimeSlotsFailure.title'),
          message: t('errors.outletStationTimeSlotsFailure.message'),
        },
        locale,
      )
      dispatch(appShowError(error))
      return rejectWithValue(error)
    }
  },
)

export const validatePreSelectedOutlet = createAppAsyncThunk<
  OutletListItem,
  { outletId: string }
>(
  'outlet/validatePreSelectedOutlet',
  async ({ outletId }, { dispatch, rejectWithValue }) => {
    const tenantIds = getTenantIds()

    try {
      const response = await api.core.outlets.get.nearby({
        outletId,
        orderFormats: AOWAllOrderFormats,
        systemVisibility: SystemVisibility.OrderWeb,
        ...(!!tenantIds.length && {
          filter: {
            tenantId: tenantIds.map(id => `in:${id}`),
          },
        }),
      })

      const outletListItem = response.data.items[0]

      if (!outletListItem?.id) {
        return rejectWithValue(new Error('Outlet not found'))
      }

      await dispatch(fetchOutletDetails({ outletId })).unwrap()

      return outletListItem
    } catch (error: any) {
      return rejectWithValue(serializeError(error))
    }
  },
)
