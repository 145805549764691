import { ServiceTimeKindType } from '@ancon/wildcat-types'

type OutletsByEnvironmentMap = Record<string, string[] | undefined>

/** Outlets fixed to Adyen LIVE environment */
export const AdyenFixedLiveOutlets: OutletsByEnvironmentMap = {
  development: ['ff1bfeac-8cd0-4678-b27a-a2df77ef8d46'],
  staging1: ['ff1bfeac-8cd0-4678-b27a-a2df77ef8d46'],
  staging2: ['ff1bfeac-8cd0-4678-b27a-a2df77ef8d46'],
  staging3: ['ff1bfeac-8cd0-4678-b27a-a2df77ef8d46'],
  qa1: ['ff1bfeac-8cd0-4678-b27a-a2df77ef8d46'],
  qa2: ['ff1bfeac-8cd0-4678-b27a-a2df77ef8d46'],
  qa3: ['ff1bfeac-8cd0-4678-b27a-a2df77ef8d46'],
  alpha: ['2d3f16b8-d96a-4b92-8bb7-699c9a91dde9'],
  beta: ['2d3f16b8-d96a-4b92-8bb7-699c9a91dde9'],
  maintenance: ['2d3f16b8-d96a-4b92-8bb7-699c9a91dde9'],
}

/** Outlets fixed to Adyen TEST environment */
export const AdyenFixedTestOutlets: OutletsByEnvironmentMap = {
  production: ['f85514be-5025-4053-8103-1727dafbc0c4'],
}

export const AsSoonAsPossibleServiceTime = {
  kind: ServiceTimeKindType.AsSoonAsPossible,
  time: '',
}
