import {
  APIDeleteRequest,
  APIGetRequest,
  APIPostRequest,
  APIPutRequest,
  CheckoutDetails,
  CheckoutStatus,
  CheckoutSummary,
  CheckoutWayOfPaymentsResponse,
  CustomerCheckoutListItem,
  PaginatedResponse,
} from '@ancon/wildcat-types'

import {
  CheckoutAPIParams,
  CreateCheckoutRequestBody,
  CreateCheckoutResponse,
  UpdateCheckoutRequestBody,
} from '../../../features/checkout/types'
import Request from '../../client/Request'
import endpoints from '../endpoints'

export type ProductPriceChangeAdditionalData = {
  productId: string
  productName: string
  oldPrice: number
  newPrice: number
  currency: string
}

type CheckoutsAPI = {
  post: {
    create: APIPostRequest<
      CreateCheckoutResponse,
      CreateCheckoutRequestBody,
      CheckoutAPIParams<'outletId'>
    >
  }
  get: {
    details: APIGetRequest<
      CheckoutDetails,
      CheckoutAPIParams<'outletId' | 'checkoutId'>
    >
    summary: APIGetRequest<
      CheckoutSummary,
      CheckoutAPIParams<'outletId' | 'checkoutId'>
    >
    wayOfPayments: APIGetRequest<
      CheckoutWayOfPaymentsResponse,
      CheckoutAPIParams<'outletId'>
    >
    checkouts: APIGetRequest<PaginatedResponse<CheckoutDetails>>
    checkoutsV2: APIGetRequest<PaginatedResponse<CustomerCheckoutListItem>>
    status: APIGetRequest<
      { status: CheckoutStatus },
      CheckoutAPIParams<'outletId' | 'checkoutId'>
    >
  }
  put: {
    update: APIPutRequest<
      { items?: ProductPriceChangeAdditionalData[] },
      UpdateCheckoutRequestBody,
      CheckoutAPIParams<'outletId' | 'checkoutId'>
    >
    applyDiscount: APIPutRequest<
      void,
      { code: string },
      CheckoutAPIParams<'outletId' | 'checkoutId'>
    >
    cancel: APIPutRequest<
      void,
      {
        cancelActor: string
        cancelActorId: string
      },
      CheckoutAPIParams<'outletId' | 'checkoutId'>
    >
  }
  delete: {
    deleteCheckout: APIDeleteRequest<
      void,
      {},
      CheckoutAPIParams<'outletId' | 'checkoutId'>
    >
  }
}

const checkouts: CheckoutsAPI = {
  post: {
    create: new Request(endpoints.core.outlets.byId.checkouts.list).post,
  },
  get: {
    details: new Request(endpoints.core.outlets.byId.checkouts.byId.details, {
      version: 1.1,
    }).get,
    summary: new Request(
      endpoints.core.outlets.byId.checkouts.byId.summary.list,
      {
        version: 1.1,
      },
    ).get,
    wayOfPayments: new Request(
      endpoints.core.outlets.byId.checkouts.wayOfPayments.list,
    ).get,
    checkouts: new Request(endpoints.core.customers.checkouts.list, {
      version: 1.1,
    }).get,
    checkoutsV2: new Request(endpoints.core.customers.checkouts.list, {
      version: 1.2,
    }).get,
    status: new Request(endpoints.core.outlets.byId.checkouts.byId.status.list)
      .get,
  },
  put: {
    update: new Request(endpoints.core.outlets.byId.checkouts.byId.details, {
      version: 1.1,
    }).put,
    applyDiscount: new Request(
      endpoints.core.outlets.byId.checkouts.byId.applyDiscount.list,
      {
        version: 1.1,
      },
    ).put,
    cancel: new Request(
      endpoints.core.outlets.byId.checkouts.byId.cancel.list,
      {
        version: 1.1,
      },
    ).put,
  },
  delete: {
    deleteCheckout: new Request(
      endpoints.core.outlets.byId.checkouts.byId.details,
      {
        version: 1.1,
      },
    ).delete,
  },
}

export default checkouts
