import { PropsWithChildren } from 'react'
import clsx from 'clsx'

import styles from './Container.module.scss'

type ContainerProps = PropsWithChildren<{
  /** fluid container will be full width */
  fluid?: boolean
  /** max width of the container */
  size?: 'sm' | 'md' | 'lg' | 'xl'
  /** Custom class name */
  className?: string
}>

export default function Container({
  children,
  fluid = true,
  size,
  className,
}: ContainerProps) {
  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.fluid]: fluid,
          [styles.sm]: size === 'sm',
          [styles.md]: size === 'md',
          [styles.lg]: size === 'lg',
          [styles.xl]: size === 'xl',
        },
        className,
      )}
    >
      {children}
    </div>
  )
}
